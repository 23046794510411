import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    CssBaseline,
    Container, 
    BottomNavigation,
    BottomNavigationAction,
} from '@material-ui/core';
import { 
    TodayOutlined,
} from '@material-ui/icons';
import moment from 'moment';
import Navbar from '../00_Nav/nav_bar';
import { 
    getUserInfoFromStorage,
} from '../../store/actions/firebase.action';
import { URL } from '../../config/config';
import RosterTab from './roster';

class RosterScreen extends Component {
    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
            loading: false,
            tab: 'this',
        };
    }

    componentDidMount = () => {
        this.refineData();
        this.props.getUserInfoFromStorage();
    }

    refineData = () => {
        const thisWeek = [];
        const nextWeek = [];
        for (let i = 0; i < 7; i++) {
            const date = moment()
            .startOf('isoWeek')
            .add(i, 'days')
            .format('ddd DD/MM/YYYY');
            const next = moment()
            .startOf('isoWeek')
            .add((i + 7), 'days')
            .format('ddd DD/MM/YYYY');
            thisWeek.push(date);
            nextWeek.push(next);
        }
        this.setState({
            thisWeek,
            nextWeek,
        });
    }

    // -------------------------- FUNCTIONS --------------------------

    handleChangeTab = (event, tab) => {
        this.setState({
            tab,
        })
    };

    // -------------------------- FIREBASE FUNCTIONS --------------------------

    // -------------------------- RENDER --------------------------


    renderBottomNav = () => {
        const { tab } = this.state;
        return (
            <BottomNavigation 
                value={tab} 
                onChange={this.handleChangeTab}
                showLabels
            >
                <BottomNavigationAction label="This Week" value="this" icon={<TodayOutlined />} />
                <BottomNavigationAction label="Next Week" value="next" icon={<TodayOutlined />} />
            </BottomNavigation>
        );
    }

    // -------------------------- MAIN --------------------------
    render() {
        const { user, branchs } = this.props;
        if (!user) return <Redirect to={URL.signin}/>
        const { tab, thisWeek, nextWeek } = this.state;
        const today = moment().format('ddd DD/MM/YYYY');
        const slot = thisWeek && thisWeek.findIndex(item => item === today);
        const branch = user && branchs && branchs.find(item => item.id === user.branch);
        const tradingName = branch && branch.tradingName;
        return (
          <div className='root-container'>
            <CssBaseline />
            <Container maxWidth='sm' className='bottom-nav'>
                <Navbar 
                    title="Roster"
                    subtitle={tradingName}
                    right={null}
                />
                <RosterTab
                    active={tab === 'this'}
                    data={thisWeek}
                    slot={slot}
                />
                <RosterTab
                    active={tab === 'next'}
                    data={nextWeek}
                    slot={0}
                />
                { this.renderBottomNav() }
            </Container>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    user: state.firebaseWeb.user,
    roster: state.firebaseWeb.roster,
    branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
    getUserInfoFromStorage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterScreen);  