import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { 
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Button,
    ButtonGroup
} from '@material-ui/core';
import { 
    TimerOutlined,
    TimerOffOutlined,
    BallotOutlined
} from '@material-ui/icons';
import { 
    ArrowBack,
    ArrowForward,
} from '@material-ui/icons';
import { orderBy } from 'lodash';
import { Loading } from '../../component/loading';
import { ALL, WORK, OFF } from '../../config/stringConfig';


class RosterTab extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            slot: 0,
            update: false,
            status: ALL,
        };
    }

    // Chuyển state từ props
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.slot !== prevState.slot && !prevState.update) {
            return { 
                slot: nextProps.slot,
            };
        }
        return null;
    }
    
    // ====================== Functions ====================== //

    // Handle switch
    preDay = () => {
        const { slot } = this.state;
        if (slot === 0) {
            return;
        }
        this.setState({
            slot: slot - 1,
            error: '',
            update: true,
        });
    }

    nextDay = () => {
        const { slot } = this.state;
        if (slot === 6) {
            return;
        }
        this.setState({
            slot: slot + 1,
            error: '',
            update: true,
        });
    }



    handleChangeStatus = ({ value }) => {
        const { status } = this.state;
        if (value === status) {
            return;
        }
        this.setState({
            status: value,
        });
    }

    // ====================== Firebase Functions ====================== //

    getRosterData = () => {
        const { rosters, data, staffs } = this.props;
        const { slot, status } = this.state;
        const date = data && data[slot];
        const filter = rosters && rosters.filter(item => {
            if (item.date === date) {
                if (status === WORK && !item.off) {
                    return true;
                } else if (status === OFF && item.off) {
                    return true;
                } else if (status === ALL) {
                    return true;
                }
            }
            return false;
        });
        const filter01 = filter && filter.map(item => {
            const staff = staffs && staffs.find(s => s.id === item.staffId);
            const firstName = staff && staff.firstName;
            const lastName = staff && staff.lastName;
            const fullName = `${firstName} ${lastName}`;
            // Work At
            const workAt = item && item.workAt;
            const secondsW = workAt && workAt.seconds;
            const timeW = secondsW ? workAt.toDate() : workAt;
            const timeWString = timeW && moment(timeW).format('hh:mm A');
            // End At
            const endAt = item && item.endAt;
            const secondsE = endAt && endAt.seconds;
            const timeE = secondsE ? endAt.toDate() : endAt;
            const timeEString = timeE && moment(timeE).format('hh:mm A');
            // Reason
            const off = item && item.off;
            const reason = item && item.reason;
            return {
                ...item,
                fullName,
                timeWString,
                timeEString,
                off,
                reason,
            };
        });
        const result = orderBy(filter01, 'fullName');
        return result;
    }

    // ====================== Render Component ====================== //

    renderDateSwitch = () => {
        const { slot, loading } = this.state;
        const { data } = this.props;
        const date = data && data[slot];
        const today = moment().format('ddd DD/MM/YYYY');
        const isToday = date === today;
        return (
            <div className='date-switch'>
                <IconButton
                    aria-label="pre-day"
                    disabled={loading}
                    onClick={() => this.preDay()}
                >
                    <ArrowBack 
                        size={25}
                        style={{ height: 35, margin: 0, opacity: slot === 0 ? 0.2 : 1 }}
                    />
                </IconButton>
                <div className='roster-date'>
                    <span style={{ textAlign: 'center', color: isToday ? '#1976d2' : '#333' }}>{date}</span>
                </div>
                <IconButton
                    aria-label="nextDay"
                    disabled={loading}
                    onClick={() => this.nextDay()}
                >
                    <ArrowForward 
                        size={25}
                        style={{ height: 35, margin: 0, opacity: slot === 6 ? 0.2 : 1 }}
                    />
                </IconButton>
            </div>
        );
    }

    renderTable = () => {
        const data = this.getRosterData();
        const length = (data && data.length) || 0;
        if (data) {
            return (
                <Paper style={{ marginBottom: 20 }}>
                    <TableContainer className='report-table'>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Staff ({length})</TableCell>
                                    <TableCell align="right">Start</TableCell>
                                    <TableCell align="right">Finish</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { data && data.map((item, index) => this.renderTableRow({ item, index })) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            );
        }
        return <Loading />;
    }

    renderTableRow = ({ item, index }) => {
        const fullName = item.fullName;
        const timeWString = item.timeWString;
        const timeEString = item.timeEString;
        const off = item.off;
        const reason = item.reason;
        const color = off ? 'red' : 'green';
        const label = off ? reason : timeWString;
        const offLabel = off ? '-' : timeEString
        return (
            <TableRow
                hover
                key={index}
            >
                <TableCell align='left'>{fullName}</TableCell>
                <TableCell align='right' style={{ color }}>{ label }</TableCell>
                <TableCell align='right' style={{ color }}>{ offLabel }</TableCell>
            </TableRow>
        );
    }



    renderFilterBar = () => {
        const { status } = this.state;
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify='flex-end'
                alignItems='center'
            >
                <Grid item>
                   {status}
                </Grid>
                <Grid item>
                    <ButtonGroup 
                        aria-label="outlined primary button group"
                        style={{ marginTop: 5 }}
                    >
                        <Button
                            className={`filter-button ${status === ALL ? 'active' : ''}`}
                            onClick={() => this.handleChangeStatus({ value: ALL }) }
                        >
                            <BallotOutlined />
                        </Button>
                        <Button 
                            className={`filter-button ${status === WORK ? 'active' : ''}`}
                            onClick={() => this.handleChangeStatus({ value: WORK }) }
                        >
                            <TimerOutlined />
                        </Button>
                        <Button 
                            className={`filter-button ${status === OFF ? 'active' : ''}`}
                            onClick={() => this.handleChangeStatus({ value: OFF }) }
                        >
                            <TimerOffOutlined />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        )
    }

    // ====================== Render Main ====================== //

    render() {
        const { active } = this.props;
        return (
          <div className={ active ? 'tab-active' : 'tab-in-active' }>
            { this.renderDateSwitch() }
            { this.renderFilterBar() }
            { this.renderTable() }
          </div>
        )
      }
}

const mapStateToProps = (state) => ({
    rosters: state.firebaseWeb.rosters,
    staffs: state.firebaseWeb.staffs,
});

// const mapDispatchToProps = {
    
// }

export default compose(connect(mapStateToProps,null))(RosterTab)

