// Tạo model cho Auth
const firebaseModel = { 
    loginError: '',
    loading: false,
    error: '',
    user: null,
    staffs: null,
    rosters: null,
    branchs: null,
};
// Phương thức thay đổi dữ liệu của model
export default (state = firebaseModel, action) => {
    switch (action.type) {
        case 'firebase_loading':
            return { 
                ...state, 
                loading: true,
            };
        case 'firebase_login_success':
            return { 
                ...state,
                loginError: '',
                loading: false,
                user: action.payload,
            };
        case 'firebase_login_fail':
            return { 
                ...state, 
                loginError: action.payload,
                loading: false,
            };
        case 'firebase_logout_success':
            return { 
                ...state, 
                loginError: '',
                loading: false,
                user: null,
            };
        case 'get_user_list_success':
            return { 
                ...state, 
                staffs: action.payload,
            };
        case 'snapshot_branch_list_success':
            return { 
                ...state, 
                branchs: action.payload,
            };
        case 'snapshot_roster_success':
            return { 
                ...state, 
                rosters: action.payload,
            };
        default:
            return state;
    }
};

