import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    Button,
    CssBaseline,
    TextField,
    InputAdornment,
    Link,
    Box,
    CircularProgress,
    Typography,
    Container,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { 
    PeopleAltOutlined 
} from '@material-ui/icons';
import { 
    firebaseLogin,
    firestoreSnapshotRoster,
} from '../../store/actions/firebase.action';
import { BRAND_NAME } from '../../config/config';
import logo from '../../config/img/logo.png'

class SignIn extends Component {

    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
            search: '',
            pincode: '',
            error: null,
            selected: null,
        }
    }
    // -------------------------- FUNCTIONS --------------------------


    selectStaff = (selected) => {
        this.setState({
            selected,
            search: '',
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleBlur = () => {
        this.setState({
            search: '',
        });
    }
    // -------------------------- FUNCTIONS FIREBASE --------------------------

    handleSubmit = (e) => {
        e.preventDefault();
        const { selected, pincode } = this.state;
        if (selected && selected.pincode === pincode) {
            this.setState({
                error: '',
            }, () => {
                this.props.firebaseLogin({ user: selected });
                this.props.firestoreSnapshotRoster({ user: selected });
            });
        } else {
            this.setState({
                error: 'Wrong Pincode',
            })
        }
    }     
    // -------------------------- RENDER --------------------------

    renderCopyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
              {BRAND_NAME}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

    renderLoginButton() {
        const { loading } = this.props;
        if (loading) {
            return (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    disabled
                >
                    Signing In <CircularProgress className="spinner" size={15}/>
                </Button>
            );
        }
        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
            >
                Sign In
            </Button>
        );
    }

    renderSearchAutocomplete = () => {
        const { selected, search } = this.state;
        const { staffs } = this.props;
        const disabled = !staffs;
        return (
            <Autocomplete
                id="search"
                onChange={(e, value) => this.selectStaff(value)}
                value={ selected }
                getOptionSelected={(option) => option}
                getOptionLabel={(option) => {
                    const username = option && option.username;
                    const firstName = option && option.firstName;
                    const lastName = option && option.lastName;
                    const fullName = `${username} - ${firstName} ${lastName}`;
                    return fullName;
                }}
                options={ search.length > 1 ? staffs : [] }
                loading={ search.length > 1 ? false : true}
                disabled={ disabled }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search by name"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PeopleAltOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                    />
                )}
            />
        );
    }

    // -------------------------- MAIN --------------------------
    render() {
        const { error } = this.state;
        const { loginError, user, auth } = this.props;
        const uid = auth.uid;
        if (user && uid) return <Redirect to={'/'}/>
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className='paper'>
                    <img src={logo} className='login-logo' alt='branch-logo' />
                    <form className="form" noValidate onSubmit={this.handleSubmit}>
                        <Typography component="h1" variant="h5" align='center'>
                            Sign in
                        </Typography>
                        <p />
                        { this.renderSearchAutocomplete() }
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="pincode"
                            label="Pincode"
                            type="password"
                            id="pincode"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox checked value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <p />
                        {this.renderLoginButton()}
                    </form>
                    <div className="red-text center">
                        { loginError ? <p>{loginError}</p> : null }
                    </div>
                    <div className="red-text center">
                        { error ? <p>{error}</p> : null }
                    </div>
                </div>
                <Box mt={8}>
                    {this.renderCopyright()}
                </Box>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    user: state.firebaseWeb.user,
    loading: state.firebaseWeb.loading,
    loginError: state.firebaseWeb.loginError,
    staffs: state.firebaseWeb.staffs,
});

const mapDispatchToProps = {
    firebaseLogin,
    firestoreSnapshotRoster,
}
  

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);
