/* eslint-disable no-unused-vars */
import firebase from 'firebase';
import moment from 'moment';
import { 
    LOCAL_USER,
    SYSTEM_EMAIL,
    SYSTEM_PASSWORD,
} from '../../config/stringConfig';
import { OWNER } from '../../config/config';

// ========================== User ==========================

export const firebaseLogin = ({ user }, callback) => (dispatch) => {
    firebaseLoading(dispatch);
    firebase.auth()
    .signInWithEmailAndPassword(SYSTEM_EMAIL, SYSTEM_PASSWORD)
    .then(() => {
        if (user) {
            const userJson = JSON.stringify(user);
            localStorage.setItem(LOCAL_USER, userJson);
            getUserInfoSuccess(dispatch, user);
            if (callback) { callback(user); }
        } else {
            firebase.auth().signOut();
            firebaseLoginFail(dispatch, 'Wrong password or your account has not been created. Please contact the administrator.');
            if (callback) { callback(false); }
        }
    })// Thành công
    .catch((error) => {
        firebaseLoginFail(dispatch, error.message);
        if (callback) { callback(false); }
    }); // Lỗi
};

const firebaseLoading = (dispatch) => {
    dispatch(
        {
            type: 'firebase_loading'
        }
    );
};

const getUserInfoSuccess = (dispatch, user) => {
    dispatch(
        {
            type: 'firebase_login_success',
            payload: user
        }
    );
};

// Đăng nhập thất bại
const firebaseLoginFail = (dispatch, error) => {
    dispatch(
        {
            type: 'firebase_login_fail',
            payload: error
        }
    );
};

export const firebaseReAuth = ({ email, password }, callback) => (dispatch) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    user.reauthenticateWithCredential(credential).then(() => {
        if (callback) { callback(true); }
    }).catch(() => {
        if (callback) { callback(false); }
    });
};

const subscribers = [];

export const getUserInfoFromStorage = (callback) => (dispatch) => {
    const userJson = localStorage.getItem(LOCAL_USER);
    if (userJson) {
        const user = JSON.parse(userJson);
        getUserInfoSuccess(dispatch, user);
        if (callback) { callback(user); }
    } else {
        subscribers.forEach(subscriber => {
            subscriber();
        });
        subscribers.length = 0;
        firebase.auth().signOut().then(() => {
            localStorage.clear();
        });
        firebaseLogoutSuccess(dispatch);
    }
};

export const firebaseLogout = () => (dispatch) => {
    subscribers.forEach(subscriber => {
        subscriber();
    });
    subscribers.length = 0;
    firebase.auth().signOut().then(() => {
        localStorage.clear();
    });
    firebaseLogoutSuccess(dispatch);
};

// Đăng xuất thành công
const firebaseLogoutSuccess = (dispatch) => {
    dispatch(
        {
            type: 'firebase_logout_success',
        }
    );
};


// -------------------------- Roster --------------------------

export const firestoreSnapshotRoster = ({ user }, callback) => (dispatch) => {
    const owner = user && user.owner;
    const branch = user && user.branch;
    const start = moment().startOf('isoWeek').startOf('day')._d;
    const end = moment().add(1, 'week').endOf('isoWeek').endOf('day')._d;
    const unsubRoster = firebase.firestore().collection('roster')
    .where('owner', '==', owner)
    .where('branch', '==', branch)
    .where('workAt', '>=', start)
    .where('workAt', '<=', end)
    .onSnapshot(snapshot => {
        if (snapshot) {
            const data = snapshot.docs.map(doc => doc.data());
            snapshotRosterSuccess(dispatch, data);
            if (callback) { callback(); }
        }
    });
    subscribers.push(unsubRoster);
};

const snapshotRosterSuccess = (dispatch, data) => {
    dispatch(
        {
            type: 'snapshot_roster_success',
            payload: data
        }
    );
};


// -------------------------- Branch --------------------------

export const firestoreSnapshotBranchList = (callback) => (dispatch) => {
    firebase.firestore().collection('branch')
    .where('owner', '==', OWNER)
    .orderBy('createAt')
    .onSnapshot(snapshot => {
        if (snapshot) {
            const data = snapshot.docs.map(doc => doc.data());
            snapshotBranchSuccess(dispatch, data);
        }
        if (callback) { callback();}
    });
}

const snapshotBranchSuccess = (dispatch, data) => {
    dispatch(
        {
            type: 'snapshot_branch_list_success',
            payload: data
        }
    );
};


// -------------------------- Staffs --------------------------
export const firestoreGetUserList = (callback) => (dispatch) => {
    firebase.firestore()
    .collection('user')
    .where('owner', '==', OWNER).orderBy('createAt')
    .get().then(snapshot => {
        if (snapshot) {
            const data = snapshot.docs.map(doc => doc.data());
            getGetUserListSuccess(dispatch, data);
        }
        if (callback) { callback();}
    });
}

const getGetUserListSuccess = (dispatch, data) => {
    dispatch(
        {
            type: 'get_user_list_success',
            payload: data
        }
    );
};
