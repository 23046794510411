import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  firebaseLogout,
  getUserInfoFromStorage,
  firestoreGetUserList,
  firestoreSnapshotBranchList,
  firestoreSnapshotRoster,
} from '../../store/actions/firebase.action';
import SignInScene from '../01_Login/login';
import RosterScreen from '../02_Home/home';
import { URL } from '../../config/config';

class Router extends Component {

  componentDidMount = () => {
    this.props.firestoreGetUserList();
    this.props.firestoreSnapshotBranchList();
    this.props.getUserInfoFromStorage((user) => {
      if (user) {
          this.props.firestoreSnapshotRoster({ user });
      }
    });
  }

  render() {
    return (
      <BrowserRouter>
          <Switch>
              <Route path={URL.home} component={RosterScreen} exact/>
              <Route path={URL.signin} component={SignInScene} />
          </Switch>
      </BrowserRouter>
      
    );
  }
}

const mapDispatchToProps = {
  firebaseLogout,
  getUserInfoFromStorage,
  firestoreGetUserList,
  firestoreSnapshotRoster,
  firestoreSnapshotBranchList
};

export default connect(null, mapDispatchToProps )(Router);