import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Erp Sanbox
export const BRAND_NAME = 'Chatters Laundry Roster'; // App Name
export const OWNER = 'inDMKYpuJFMTYHq5kHMD63PHkpk1';
export const SANDBOX = false;
const firebaseConfig = {
  apiKey: "AIzaSyA_RrHa3HPRafer1g93b0P8chyTyPBWTjE",
  authDomain: "chatters-laundry.firebaseapp.com",
  databaseURL: "https://chatters-laundry.firebaseio.com",
  projectId: "chatters-laundry",
  storageBucket: "chatters-laundry.appspot.com",
  messagingSenderId: "401415329518",
  appId: "1:401415329518:web:94fac9199019fb6a4445ff",
  measurementId: "G-RPFJNEMH3Y"
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const authApp = firebase.initializeApp(firebaseConfig, 'authApp');


// ========================= App String ========================= //
export const URL = { // url
    home: '/',
    signin: '/signin',
}
